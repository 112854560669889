import React from 'react';
// import GoogleMapReact from 'google-map-react';
import BlockMap from '../blocks/BlockMap';

const Local = () => {
  return (
    <>
      <div className="page-header">
        <div className="page-header__container container">
          <div className="page-header__breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                  {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                    <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                  </svg>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                <li className="breadcrumb-item active">
                  <a href="/local">Local Delivery</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="local-delivery">
        <div className="container">
          <div className="row">
            <h1 className="mb-3">Local Delivery</h1>
            <p>
              We deliver freshness! From our quality meats to frozen pizzas and ready-to-eat prepared meals, your order
              needs to be delivered on time. From our store in Bala, Ontario Canada, we deliver up to 60km from our
              store in our custom refrigerated van, design specifically to ensure your order is compliant with food
              safety regulations and the HACCP guidelines. Based on your request at the time of order, we deliver on
              time using reliable refrigerated vehicles and team members who are committed your entire order is always
              fresh and undamaged when it reaches your home.
            </p>
            <h5>Delivery is always fast - and local with a smile!</h5>
            <p>
              We only deliver locally within our service area, using our own drivers and vehicles. In some cases, we uer
              carefully selected partners who we know and trust to get your order delivered without incident. It is part
              of our promise to you, as a part of the Cottage Butcher family.
            </p>
            <p>
              We email to confirm your delivery date at time or send you an SMS. You may also get an alert in the
              Cottage Butcher app (coming soon). Count on us to get your order to you – when you need it most!
            </p>
            <p>
              Our happy team member will deliver your order right to your front door. For perishable items or meats,
              your order arrive in an insulated bag with cold packing to ensure freshness. We vacuum-seal meat at peak
              freshness, and it reaches you still sealed and ready to prepare. It’s that easy.
            </p>
            <h5>Our Delivery Service Area</h5>
            <BlockMap />
            <h5>Delivery Service Fees</h5>
            <p>Your delivery in the township of Bala is ALWAYS FREE as long as you spend $100 or more.</p>
            <p>
              Delivery fees beyond Bala are calculated at checkout based on your delivery address at $1.64 per
              kilometer. Same day delivery is an additional convenience fee of $11.95.
            </p>
            <h5>Don't live in our service area, or not sure?</h5>
            <p>If you're not sure, please email and ask!</p>
            <p>
              If you are a resident of a community in the Monatak or within the 60km service are not currently served by
              Cottage Butcher and are interested in us getting to your neighborhood, let us know by sending an email to
              delivertome@cottagebutcher.com including your community name and postal code.
            </p>
            <p>
              By letting us know your interest -- and encouraging friends to do the same -- you can help unlock your
              community for Cottage Butcher! This lets us plan new stores and service delivery areas to meet our growing
              demand. We value your business and look forward to serving you.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Local;

export default [
  // {
  //     id: 1,
  //     title: 'A Variety Of Other Academic And Non-Academic Approaches Have Been Explored',
  //     image: 'images/posts/post-1.jpg',
  //     categories: ['Special Offers'],
  //     date: 'October 19, 2019',
  // },
  // {
  //     id: 2,
  //     title: 'Logic Is The Study Of Reasoning And Argument Part 2',
  //     image: 'images/posts/post-2.jpg',
  //     categories: ['Latest News'],
  //     date: 'September 5, 2019',
  // },
  // {
  //     id: 3,
  //     title: 'Some Philosophers Specialize In One Or More Historical Periods',
  //     image: 'images/posts/post-3.jpg',
  //     categories: ['New Arrivals'],
  //     date: 'August 12, 2019',
  // },
  // {
  //     id: 4,
  //     title: 'Philosophy That Addresses Topics Such As Goodness',
  //     image: 'images/posts/post-4.jpg',
  //     categories: ['Special Offers'],
  //     date: 'Jule 30, 2019',
  // },
  // {
  //     id: 5,
  //     title: 'Germany Was The First Country To Professionalize Philosophy',
  //     image: 'images/posts/post-5.jpg',
  //     categories: ['New Arrivals'],
  //     date: 'June 12, 2019',
  // },
  // {
  //     id: 6,
  //     title: 'Logic Is The Study Of Reasoning And Argument Part 1',
  //     image: 'images/posts/post-6.jpg',
  //     categories: ['Special Offers'],
  //     date: 'May 21, 2019',
  // },
  // {
  //     id: 7,
  //     title: 'Many Inquiries Outside Of Academia Are Philosophical In The Broad Sense',
  //     image: 'images/posts/post-7.jpg',
  //     categories: ['Special Offers'],
  //     date: 'April 3, 2019',
  // },
  // {
  //     id: 8,
  //     title: 'An Advantage Of Digital Circuits When Compared To Analog Circuits',
  //     image: 'images/posts/post-8.jpg',
  //     categories: ['Latest News'],
  //     date: 'Mart 29, 2019',
  // },
  // {
  //     id: 9,
  //     title: 'A Digital Circuit Is Typically Constructed From Small Electronic Circuits',
  //     image: 'images/posts/post-9.jpg',
  //     categories: ['New Arrivals'],
  //     date: 'February 10, 2019',
  // },
  // {
  //     id: 10,
  //     title: 'Engineers Use Many Methods To Minimize Logic Functions',
  //     image: 'images/posts/post-10.jpg',
  //     categories: ['Special Offers'],
  //     date: 'January 1, 2019',
  // },
];

export default [
  // {
  //     id: 1,
  //     author: 'Emma Williams',
  //     postTitle: 'Nullam at varius sapien sed sit amet condimentum elit',
  //     text: 'In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge...',
  //     date: '3 minutes ago',
  // },
  // {
  //     id: 2,
  //     author: 'Airic Ford',
  //     postTitle: 'Integer efficitur efficitur velit non pulvinar pellentesque dictum viverra',
  //     text: 'In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge...',
  //     date: '25 minutes ago',
  // },
  // {
  //     id: 3,
  //     author: 'Loyd Walker',
  //     postTitle: 'Curabitur quam augue vestibulum in mauris fermentum pellentesque libero',
  //     text: 'In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge...',
  //     date: '2 hours ago',
  // },
  // {
  //     id: 4,
  //     author: 'Jessica Moore',
  //     postTitle: 'Vestibulum leo sapien sollicitudin at magna eu interdum congue feugiat',
  //     text: 'In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge...',
  //     date: '3 days ago',
  // },
];

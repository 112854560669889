// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

export default function BlockFeatures(props) {
  const { layout } = props;

  return (
    <div className={`block block-features block-features--layout--${layout}`}>
      <div className="container">
        <div className="block-features__list">
          <div className="block-features__item">
            <div className="block-features__icon">
              <img src="../../../images/icon/pick-up.svg" alt="" />
            </div>
            <div className="block-features__content">
              <div className="block-features__title">Pick-Up</div>
              {/* <div className="block-features__subtitle">For orders from $50</div> */}
            </div>
          </div>
          <div className="block-features__divider" />
          <div className="block-features__item">
            <div className="block-features__icon">
              <img src="../../../images/icon/delivery.svg" alt="" />
            </div>
            <div className="block-features__content">
              <div className="block-features__title">Delivery</div>
              {/* <div className="block-features__subtitle">Call us anytime</div> */}
            </div>
          </div>
          <div className="block-features__divider" />
          <div className="block-features__item">
            <div className="block-features__icon">
              <img src="../../../images/icon/crates.svg" alt="" />
            </div>
            <div className="block-features__content">
              <div className="block-features__title">Cottage Crates</div>
              {/* <div className="block-features__subtitle">Only secure payments</div> */}
            </div>
          </div>
          <div className="block-features__divider" />
          <div className="block-features__item">
            <div className="block-features__icon">
              <img src="../../../images/icon/ordering.svg" alt="" />
            </div>
            <div className="block-features__content">
              <div className="block-features__title">24/7 Ordering</div>
              {/* <div className="block-features__subtitle">Discounts up to 90%</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BlockFeatures.propTypes = {
  layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
  layout: 'classic',
};

export default [
  // { id: 1, name: 'Latest News', url: '' },
  // {
  //     id: 2,
  //     name: 'Special Offers',
  //     url: '',
  //     children: [
  //         { id: 3, name: 'Spring Sales', url: '' },
  //         { id: 4, name: 'Summer Sales', url: '' },
  //         { id: 5, name: 'Autumn Sales', url: '' },
  //         { id: 6, name: 'Christmas Sales', url: '' },
  //         { id: 7, name: 'Other Sales', url: '' },
  //     ],
  // },
  // { id: 8, name: 'New Arrivals', url: '' },
  // { id: 9, name: 'Reviews', url: '' },
  // { id: 10, name: 'Drills and Mixers', url: '' },
  // { id: 11, name: 'Cordless Screwdrivers', url: '' },
  // { id: 12, name: 'Screwdrivers', url: '' },
  // { id: 14, name: 'Wrenches', url: '' },
];

import fetchIntercept from 'fetch-intercept';
import { getUserData, getLocalData } from './storage';

export const AuthInterceptor = () => {
  let debug = process.env.REACT_APP_DEBUG;
  let userData = getUserData();
  let localData = getLocalData('tempUser');
  fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here

      if (config) {
        if (userData.isLoggedIn) {
          // let formData = new FormData();
          if (config.body instanceof FormData) {
            if (config.headers && config.headers.token) {
              config.headers = {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + config.headers.token,
                // 'Access-Control-Allow-Origin': 'http://localhost:3000',
                // 'Access-Control-Allow-Credentials': true,`
              };
            } else {
              config.headers = {
                Authorization: 'Bearer ' + userData.token,
                Accept: 'application/json',
                // 'Access-Control-Allow-Origin': 'http://localhost:3000',
                // 'Access-Control-Allow-Credentials': true,
              };
            }
          } else {
            if (config.headers && config.headers.token) {
              config.headers = {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', //for stripe
                Authorization: 'Bearer ' + config.headers.token,
                Accept: 'application/json',
                // 'Access-Control-Allow-Origin': 'http://localhost:3000',
                // 'Access-Control-Allow-Credentials': true,
              };
            } else {
              config.headers = {
                Authorization: 'Bearer ' + userData.token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
                // 'Access-Control-Allow-Origin': 'http://localhost:3000',
                // 'Access-Control-Allow-Credentials': true,
              };
            }
          }
        } else {
          if (config.headers && config.headers.token) {
            config.headers = {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + config.headers.token,
              Accept: 'application/json',
            };
          } else {
            config.headers = {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            };
          }
        }
      }

      if (debug) {
      }
      // config.mode = 'no-cors';

      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      if (debug) {
        console.log('Error: ', error);
      }

      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      if (debug) {
        response
          .clone()
          .json()
          .then((data) => {});
      }

      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      if (debug) {
        console.log('Error: ', error);
      }

      return Promise.reject(error);
    },
  });
};

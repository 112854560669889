// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { API_GET_ADDRESS, API_PLACE_ORDER } from '../../config/endpoints';

// data stubs
import theme from '../../data/theme';
import { getUserData } from '../../services/storage';
import { encryptString, getOrderPaymentStatus } from '../../services/helpers';
const loggedInUser = getUserData();

export default function AccountPageDashboard() {
  const [orderList, setOrderList] = useState({ data: [] });
  const [addressList, setAddressList] = useState([{ attributes: {} }]);

  useEffect(() => {
    fetch(API_GET_ADDRESS, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setAddressList(data.data);
      });
  }, []);

  useEffect(() => {
    fetch(
      API_PLACE_ORDER +
        '?page[offset]=0&page[limit]=3&sort=-order.id&include=order/address,order/coupon,order/product,order/service',
      {
        method: 'GET',
        crossDomain: true,
        credentials: 'include',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setOrderList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const address = addressList[0] ? addressList[0] : { attributes: {} };

  return (
    <div className="dashboard">
      <Helmet>
        <title>{`My Account — ${theme.name}`}</title>
      </Helmet>

      <div className="dashboard__profile card profile-card">
        <div className="card-body profile-card__body">
          <div className="profile-card__avatar">
            <img src="images/avatars/avatar-3.jpg" alt="" />
          </div>
          <div className="profile-card__name">
            {loggedInUser.user.firstname} {loggedInUser.user.lastname}
          </div>
          <div className="profile-card__email">{loggedInUser.user.email}</div>
          <div className="profile-card__edit">
            <Link to="profile" className="btn btn-secondary btn-sm">
              Edit Profile
            </Link>
          </div>
        </div>
      </div>
      {/* {address.attributes.length > 0 ? ( */}
      <div className="dashboard__address card address-card address-card--featured">
        {/* {address.default && <div className="address-card__badge">Default Address</div>} */}
        <div className="address-card__body">
          <div className="address-card__name">{`${address.attributes['customer.address.firstname']} ${address.attributes['customer.address.lastname']}`}</div>
          <div className="address-card__row">
            {address.attributes['customer.address.address1']}
            <br />
            {address.attributes['customer.address.city']}, {address.attributes['customer.address.state']},{' '}
            {address.attributes['customer.address.postal']}
            <br />
            {address.attributes['customer.address.countryid']}
          </div>
          <div className="address-card__row">
            <div className="address-card__row-title">Phone Number</div>
            <div className="address-card__row-content"> {address.attributes['customer.address.telephone']}</div>
          </div>
          {/* <div className="address-card__footer">
                <Link to={`/account/addresses/` + address.id}>Edit</Link>
                &nbsp;&nbsp;
                <a href="javascript:;" onClick={() => handleAddressRemove(address.id)}>
                  Remove
                </a>
              </div> */}
        </div>
      </div>
      {/* ) : null} */}

      <div className="dashboard__orders card">
        {/* <div className="address-card__badge">Coming Soon</div> */}
        <div className="card-header">
          <h5>Recent Orders</h5>
        </div>
        <div className="card-divider" />
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {orderList.data.map((order) => (
                  <tr key={order.id}>
                    <td>
                      <Link to={`/account/orders/${encryptString(order.id)}`}>#{order.id}</Link>
                    </td>
                    <td>{order.attributes['order.ctime']}</td>
                    <td>{getOrderPaymentStatus(order.attributes['order.statuspayment'])}</td>

                    <td>
                      $
                      {(
                        parseFloat(order.attributes['order.price']) + parseFloat(order.attributes['order.taxvalue'])
                      ).toFixed(2)}
                    </td>
                    {/* <td>{order.status}</td>
                    <td>{order.total}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

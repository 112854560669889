import React from 'react';

const RewardProgram = () => {
  return (
    <>
      <div className="page-header">
        <div className="page-header__container container">
          <div className="page-header__breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                  {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                    <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                  </svg>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                <li className="breadcrumb-item active">
                  <a href="/reward-program">Rewards Program</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="reward-program">
        <div className="container">
          <div className="row">
            <h1 className="mb-3">Cottage Butcher “Cut Above” Rewards Program</h1>
            <h5>Overview</h5>
            <p>
              Join the “Cut Above” Rewards Program and get a little something effort with each in-store and online
              purchase! Every time you make a purchase with the Cottage Butcher, you’ll earn credits. Use those credits
              for discounts on merchandise, upgrade your selection from the butcher case, access unique experiences with
              us, and more! And the Cut Above Rewards Program is completely free.
            </p>
            <h5>Rewards</h5>
            <h6>Earn 100 credits for every dollar you spend on everything in our store, online and in the app</h6>
            <h6>Status Levels</h6>
            <ul className="list-group">
              <li>
                Level 1: Continental
                <ul>
                  <li>Starting level for everyone who creates an account</li>
                  <li>VIP BONUS: After 6+ visits per year, receive 250 bonus credits.</li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>Level 2: 1K</li>
              <ul>
                <li>Earn this level after 10 visits or $1,000 in spend</li>
                <li>VIP BONUS: After 10+ visits per year, receive 500 bonus credits.</li>
              </ul>
            </ul>
            <ul>
              <li>
                Level 3: Elite
                <ul>
                  <li>Earn this level after 50 visits or $3,500 in spend</li>
                  <li>VIP BONUS: After 50+ visits per year, receive 1,000 bonus credits.</li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                Level 4: Reserved
                <ul>
                  <li>Earn this level after 100 visits or $8,500 in spend</li>
                  <li>After 100+ visits per year, receive 1,000 bonus credits.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardProgram;

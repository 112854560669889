import React from 'react';
// blocks
import BlockMap from '../blocks/BlockMap';

const StoreLocation = () => {
  return (
    <>
      <BlockMap />
    </>
  );
};

export default StoreLocation;

// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// blocks
import BlockLoader from '../blocks/BlockLoader';

// data stubs
import { API_GET_CATALOG } from '../../config/endpoints';
import { categoryPageUrl, getCategoryDetailsMultiple } from '../../services/helpers';

function CategoryList(props) {
  const { productSlug, layout, sidebarPosition } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState({ included: [] });

  const getAllCategories = () => {
    fetch(API_GET_CATALOG + '?include=catalog,media,price&id=' + process.env.REACT_APP_MAIN_CATEGORY_ID, {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setCategoriesList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  if (isLoading) {
    return <BlockLoader />;
  }

  return (
    <React.Fragment>
      <Helmet>{/* <title>{`${product.name} — ${theme.name}`}</title> */}</Helmet>

      {/* <PageHeader breadcrumb={breadcrumb} /> */}
      <div className="site__body">
        <div className="page-header">
          <div className="page-header__container container">
            <div className="page-header__breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6} height={9} className="breadcrumb-arrow">
                      <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                    </svg>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Categories
                  </li>
                </ol>
              </nav>
            </div>
            <div className="page-header__title">
              <h1>Categories</h1>
              <div className="block-categories__list mt-4">
                {categoriesList.included.map((item, key) =>
                  item.type == 'catalog' ? (
                    <>
                      <div
                        key={key}
                        className="block-categories__item category-card category-card--layout--compact category--compact"
                      >
                        <a href={categoryPageUrl(item)} className="category__items__card">
                          <div className=" category-card__body">
                            <div className=" category-card__image">
                              <img
                                src={
                                  getCategoryDetailsMultiple(categoriesList, item).media
                                    ? getCategoryDetailsMultiple(categoriesList, item).media
                                    : 'images/products/product-7.jpg'
                                }
                                alt=""
                              />
                            </div>
                            <div className=" category-card__content">
                              <div className=" category-card__name">
                                <a href={categoryPageUrl(item)}>{item.attributes['catalog.label']}</a>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </>
                  ) : null
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

CategoryList.propTypes = {
  /** Product slug. */
  productSlug: PropTypes.string,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
  /**
   * sidebar position (default: 'start')
   * one of ['start', 'end']
   * for LTR scripts "start" is "left" and "end" is "right"
   */
  sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

CategoryList.defaultProps = {
  layout: 'standard',
  sidebarPosition: 'start',
};

export default CategoryList;

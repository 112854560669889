// react
import React from 'react';

function ProductTabDescription() {
  return (
    <div className="typography">
      <h3>Product Full Description</h3>
      <p>
        DELICIOUS, AUTHENTIC NASHVILLE HOT CHICKEN
        <br />A chicken sandwich is only as good as its ingredients. That&apos;s why we import our spices and use
        top-quality ingredients in each of our Nashville Hot Chicken tenders, as well as our other offerings. We pride
        ourselves in following the traditional Hot Chicken seasonings and processes used in Nashville, the birthplace of
        Hot Chicken. From dry spice to the classic hot oil, our Hot Chicken is as Nashvillian as it gets. Here, ancient
        customs and future trends coexist, and the intricacies of African culture blend seamlessly together over an
        Ethiopian-brewed coffee, or a bowl of Senegalese black-eyed pea stew and a side of spicy Ghanaian plantains.
      </p>
      {/* <h3>Etiam lacus lacus mollis in mattis</h3> */}
      <p>
        Our signature baba&apos;s sandwich comes with 2 crispy tenders, our simple slaw kosher pickles, and our
        signature house sauce, all served on a buttery brioche bun. comes with your choice of side. Two crispy tenders
        seasoned to perfection, served traditionally on regular white bread. Comes with your choice of side. spicy.
        Crinkle cut fries topped with our simple slaw, choppe tenders, cheese sauce, ranch, and our signature house
        sauce. we will not deconstruct the loaded fry
      </p>
      <p>
        Mix up the traditional steak and potatoes and add a little color to your next catering event with glazed skirt
        steak fajitas. These are perfect for a crowd plate them beautifully or let your guests customize one at a
        buffet-style event with olive oil roasted sweet peppers and onions, chunky tomato and cilantro salsa, salty
        queso fresco cheese, and sour cream. Any way you slice it, these fajitas will add a memorable spice to your
        event. Salmon is a classic standby in every chef’s repertoire, but instead of a traditional presentation, mix it
        up! Pair creamy pasta with pan-seared salmon and spaghetti Carbonara flavors to leave a lasting impression.
        Bavette with salmon, asparagus, and peas in pesto cream sauce will certainly leave guests with something to
        remember.
      </p>
    </div>
  );
}

export default ProductTabDescription;

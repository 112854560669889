// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// blocks
import BlockLoader from '../blocks/BlockLoader';

// data stubs
import { API_GET_CART } from '../../config/endpoints';
import { getMediaUrlFromString, getProductUrl, parseAmount } from '../../services/helpers';
import { Link } from 'react-router-dom';

function CartPage(props) {
  const { productSlug, layout, sidebarPosition } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState({ products: [], details: { attributes: [] } });
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [latestProducts, setLatestProducts] = useState([]);

  // Load product.
  useEffect(() => {
    setIsLoading(true);
    fetch(API_GET_CART + '?id=default', {
      method: 'GET',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        const cartProducts = data.included.filter((product) => {
          return product.type === 'basket.product';
        });
        // setCart(data);
        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRemoveCart = (e, item) => {
    e.preventDefault();
    fetch(API_GET_CART + '?id=default&related=product&relatedid=' + item, {
      method: 'DELETE',
      crossDomain: true,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        const cartProducts = data.included.filter((product) => {
          return product.type === 'basket.product';
        });

        // setCart(data);
        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
        }));
      });
  };

  const handleClearCart = () => {
    fetch(API_GET_CART + '?id=default', {
      method: 'DELETE',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCart({
          products: [],
          details: { attributes: [] },
          cart: [],
        });
        // setIsCartClearLoading(false);
        // handleNewOrder();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddProduct = (e, item, qty) => {
    e.preventDefault();
    fetch(API_GET_CART + '?id=default&related=product&relatedid=' + item, {
      method: 'PATCH',
      crossDomain: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          attributes: {
            quantity: qty,
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const cartProducts = data.included.filter((product) => {
          return product.type === 'basket.product';
        });

        // setCart(data);
        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
        }));
      });
  };

  if (isLoading) {
    return <BlockLoader />;
  }

  return (
    <React.Fragment>
      <Helmet>{/* <title>{`${product.name} — ${theme.name}`}</title> */}</Helmet>

      {/* <PageHeader breadcrumb={breadcrumb} /> */}
      <div className="site__body">
        <div className="page-header">
          <div className="page-header__container container">
            <div className="page-header__breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6} height={9} className="breadcrumb-arrow">
                      <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                    </svg>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Shopping Cart
                  </li>
                </ol>
              </nav>
            </div>
            <div className="page-header__title">
              <h1>Shopping Cart</h1>
            </div>
          </div>
        </div>
        <div className="cart block">
          <div className="container">
            {cart.products.length > 0 ? (
              <>
                <table className="cart__table cart-table">
                  <thead className="cart-table__head">
                    <tr className="cart-table__row">
                      <th className="cart-table__column cart-table__column--image">Image</th>
                      <th className="cart-table__column cart-table__column--product">Product</th>
                      <th className="cart-table__column cart-table__column--price text-center">Price</th>
                      <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                      <th className="cart-table__column cart-table__column--total">Total</th>
                      <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                    </tr>
                  </thead>
                  <tbody className="cart-table__body">
                    {cart.products.map((item, key) => (
                      <tr key={key} className="cart-table__row">
                        <td className="cart-table__column cart-table__column--image">
                          <div className="product-image">
                            {/* <a className="product-image__body" href={getProductUrl(item)}> */}
                            <a className="product-image__body">
                              <img
                                className="product-image__img"
                                src={getMediaUrlFromString(item.attributes['order.product.mediaurl'])}
                                alt=""
                              />
                            </a>
                          </div>
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                          <a className="cart-table__product-name" href={getProductUrl(item)}>
                            {item.attributes['order.product.name']}
                            {item.variants &&
                              item.variants.map((variant, key) => (
                                <div key={key}>
                                  <label> {variant['variantLabel']}</label> <span>{variant['attribute.label']} </span>
                                </div>
                              ))}
                          </a>
                        </td>
                        <td className="cart-table__column cart-table__column--price text-center" data-title="Price">
                          {item.attributes['order.product.rebate'] !== '0.00' && (
                            <span className="me-2">
                              {' '}
                              (
                              <strike
                                style={{
                                  fontWight: 500,
                                }}
                              >
                                $
                                {(
                                  item.attributes['order.product.price'] - item.attributes['order.product.rebate']
                                ).toFixed(2)}
                              </strike>
                              )
                            </span>
                          )}
                          ${parseAmount(item.attributes['order.product.price'])}
                        </td>
                        <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                          <div className="input-number">
                            <input
                              className="form-control input-number__input"
                              type="number"
                              min={1}
                              step={1}
                              defaultValue={item.attributes['order.product.quantity']}
                              value={item.attributes['order.product.quantity']}
                            />
                            <div
                              className="input-number__add"
                              onClick={(e) =>
                                handleAddProduct(e, item.id, item.attributes['order.product.quantity'] + 1)
                              }
                            />
                            <div
                              className="input-number__sub"
                              onClick={(e) =>
                                item.attributes['order.product.quantity'] === 1
                                  ? null
                                  : handleAddProduct(e, item.id, item.attributes['order.product.quantity'] - 1)
                              }
                            />
                          </div>
                        </td>
                        <td className="cart-table__column cart-table__column--total" data-title="Total">
                          $
                          {parseAmount(
                            item.attributes['order.product.price'] * item.attributes['order.product.quantity']
                          )}
                        </td>
                        <td className="cart-table__column cart-table__column--remove">
                          <button
                            type="button"
                            className="btn btn-light btn-sm btn-svg-icon"
                            onClick={(e) => handleRemoveCart(e, item.id)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12}>
                              <path d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6 c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4 C11.2,9.8,11.2,10.4,10.8,10.8z" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* <div className="cart__actions">
                  <form className="cart__coupon-form">
                    <label htmlFor="input-coupon-code" className="sr-only">
                      Password
                    </label>
                    <input type="text" className="form-control" id="input-coupon-code" placeholder="Coupon Code" />
                    <button type="submit" className="btn btn-primary">
                      Apply Coupon
                    </button>
                  </form>
                  <div className="cart__buttons">
                    <a className="btn btn-light" href="/">
                      Continue Shopping
                    </a>
                  </div>
                </div> */}

                {/* <div className="cart__actions">
                  <div className="cart__buttons">
                    <a className="btn btn-light" onClick={() => handleClearCart()}>
                      Clear Cart
                    </a>
                  </div>
                </div> */}
                <div className="row justify-content-end pt-md-5 pt-4">
                  <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">Cart Totals</h3>
                        <table className="cart__totals">
                          <thead className="cart__totals-header">
                            <tr>
                              <th>Subtotal</th>
                              <td>${parseAmount(cart.details.attributes['order.price'])}</td>
                            </tr>
                          </thead>
                          <tbody className="cart__totals-body">
                            {cart.details.attributes['order.rebate'] !== '0.00' ? (
                              <tr>
                                <th>Discount</th>
                                <td>${parseAmount(cart.details.attributes['order.rebate'].split('-')[1])}</td>
                              </tr>
                            ) : null}
                            <tr>
                              <th>Tax</th>
                              <td>${parseAmount(cart.details.attributes['order.taxvalue'])}</td>
                            </tr>
                            <tr>
                              <th>Delivery</th>
                              <td>
                                $
                                {parseAmount(cart.details.attributes['order.costs']) === 0
                                  ? 'Delivery rate to be calculate on next step'
                                  : parseAmount(cart.details.attributes['order.costs'])}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot className="cart__totals-footer">
                            <tr>
                              <th>Total</th>
                              <td>${parseAmount(cart.details.attributes['order.final_amount'])}</td>
                            </tr>
                          </tfoot>
                        </table>
                        <a className="proc-ptm btn-primary btn-block text-center" href="/shop/checkout">
                          Proceed to checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div class="col-12 mb-3">
                    <div class="alert alert-primary alert-lg">
                      Your shopping cart is empty! <Link to={'/'}>Click here to shop.</Link>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

CartPage.propTypes = {
  /** Product slug. */
  productSlug: PropTypes.string,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
  /**
   * sidebar position (default: 'start')
   * one of ['start', 'end']
   * for LTR scripts "start" is "left" and "end" is "right"
   */
  sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

CartPage.defaultProps = {
  layout: 'standard',
  sidebarPosition: 'start',
};

export default CartPage;

import React from 'react';
import IndicatorAccount from '../header/IndicatorAccount';
import { useState } from 'react';

const AccountLogin = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClose = () => {
    setIsClicked(true);
  };

  return (
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-form">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Log to Continue
            </h5>
            <button
              type="button"
              class="btn-close close-login-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div class="modal-body">
            <IndicatorAccount isClicked={isClicked} setIsClicked={setIsClicked} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLogin;

// react
import React, { Component, useEffect, useState } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// application
// import Pagination from '../shared/Pagination';

// data stubs
import theme from '../../data/theme';
import { API_PLACE_ORDER } from '../../config/endpoints';
import { encryptString, getOrderPaymentStatus, parseAmount } from '../../services/helpers';

export default function AccountPageOrders() {
  const [orderList, setOrderList] = useState({ data: [] });

  useEffect(() => {
    fetch(
      API_PLACE_ORDER +
        '?page[offset]=0&page[limit]=100&sort=-order.id&include=order/address,order/coupon,order/product,order/service',
      {
        method: 'GET',
        crossDomain: true,
        credentials: 'include',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setOrderList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="card">
      <Helmet>
        <title>{`Order History — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <h5>Order History</h5>
      </div>
      <div className="card-divider" />
      <div className="card-table">
        <div className="table-responsive-sm">
          <table>
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Payment Status</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orderList.data.map((order) => (
                <tr key={order.id}>
                  <td>
                    <Link to={`/account/orders/${encryptString(order.id)}`}>#{order.id}</Link>
                  </td>
                  <td>{order.attributes['order.ctime']}</td>
                  <td>{getOrderPaymentStatus(order.attributes['order.statuspayment'])}</td>
                  <td>${parseAmount(order.attributes['order.final_amount'])}</td>
                </tr>
              ))}
            </tbody>
            {/* <tbody>{ordersList}</tbody> */}
          </table>
        </div>
      </div>
      <div className="card-divider" />
      <div className="card-footer">
        {/* <Pagination current={page} total={3} onPageChange={this.handlePageChange} /> */}
      </div>
    </div>
  );
}

// eslint-disable react/destructuring-assignment
// react
import React, { useMemo, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// application
import Footer from './footer';
import Header from './header';
import Quickview from './shared/Quickview';

// pages
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import BlogPageCategory from './blog/BlogPageCategory';
import BlogPagePost from './blog/BlogPagePost';
import CartPage from './shop/CartPage';
import PageCompare from './shop/ShopPageCompare';
import PageWishlist from './shop/ShopPageWishlist';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
import ShopPageProduct from './shop/ShopPageProduct';
import ProductView from './shop/ProductView';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageComponents from './site/SitePageComponents';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageContactUsAlt from './site/SitePageContactUsAlt';
import SitePageFaq from './site/SitePageFaq';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';
import SitePageTypography from './site/SitePageTypography';
import StoreLocation from './site/StoreLocation';
import QuestionsPage from './site/QuestionsPage';
import TermsAndConditions from './site/TermsAndConditions';
// data stubs
import theme from '../data/theme';
import CartCheckout from './shop/CartCheckout';
import HomePageTwo from './home/HomePageTwo';
import CategoryPage from './shop/CategoryPage';
import Local from './site/Local';
import ReturnsPolicy from './site/ReturnsPolicy';
import OrderAdd from './site/OrderAdd';
import ProductRecall from './site/ProductRecall';
import DeliveryInsurance from './site/DeliveryInsurance';
import PickUpOrder from './site/PickUpOrder';
import ReferringFriend from './site/ReferringFriend';
import RewardProgram from './site/RewardProgram';
import CategoryList from './shop/CategoryList';
import OrderConfirm from './site/OrderConfirm';
import AccountLogin from './account/AccountLogin';

// const categoryLayouts = [
//   ['/shop/category-grid-3-columns-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'start' }],
//   ['/shop/category-grid-4-columns-full', { columns: 4, viewMode: 'grid' }],
//   ['/shop/category-grid-5-columns-full', { columns: 5, viewMode: 'grid' }],
//   ['/shop/category-list', { columns: 3, viewMode: 'list', sidebarPosition: 'start' }],
//   ['/shop/category-right-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'end' }],
// ].map(([url, options]) => (
//   <Route
//     key={url}
//     exact
//     path={url}
//     render={(props) => <ShopPageCategory {...props} {...options} categorySlug="power-tools" />}
//   />
// ));

// const productLayouts = [
//   ['/shop/product-standard', { layout: 'standard' }],
//   ['/shop/product-columnar', { layout: 'columnar' }],
//   ['/shop/product-sidebar', { layout: 'sidebar' }],
// ].map(([url, options]) => (
//   <Route
//     key={url}
//     exact
//     path={url}
//     render={(props) => <ShopPageProduct {...props} {...options} productSlug="brandix-screwdriver-screw1500acc" />}
//   />
// ));

function Layout(props) {
  const { match, headerLayout, homeComponent } = props;

  const [cartData, setCartData] = useState({ products: [], included: [], details: { attributes: [] } });

  function handleCartData(data) {
    setCartData(data);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{theme.name}</title>
        <meta name="description" content={theme.fullName} />
      </Helmet>
      <ToastContainer autoClose={5000} hideProgressBar enableHtml={true} />
      <Quickview />
      {/* <MobileMenu /> */}

      <div className="site">
        <header className="site__header d-lg-none d-none">{/* <MobileHeader /> */}</header>
        <header className="site__header d-lg-block d-block">
          {useMemo(
            () => (
              <Header layout={headerLayout} cartData={cartData} />
            ),
            [cartData]
          )}
        </header>

        <div className="site__body">
          <Switch>
            <Route exact path="/" render={(props) => <HomePageTwo {...props} onCartData={handleCartData} />} />

            <Redirect exact from="/shop" to="/shop/catalog" />
            <Route
              exact
              path="/shop/category/:categoryId/:url"
              render={(props) => <CategoryPage {...props} onCartData={handleCartData} />}
            />
            <Route
              exact
              path="/shop/categories"
              render={(props) => <CategoryList {...props} onCartData={handleCartData} />}
            />
            <Route
              exact
              path="/shop/catalog/:categorySlug"
              render={(props) => (
                <ShopPageCategory
                  {...props}
                  columns={3}
                  viewMode="grid"
                  sidebarPosition="start"
                  categorySlug={props.match.params.categorySlug}
                />
              )}
            />
            {/* <Route
              exact
              path="/shop/catalog"
              render={(props) => <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />}
            />
            <Route path="/account/orders/:id" component={OrderViewPage} /> */}

            {/* Following category layouts only for demonstration. */}
            <Route
              exact
              path="/shop/products/:productSlug"
              render={(props) => (
                <ShopPageProduct {...props} layout="standard" productSlug={props.match.params.productSlug} />
              )}
            />
            <Route
              exact
              path="/product/:id/:productSlug"
              render={(props) => (
                <ProductView
                  {...props}
                  layout="standard"
                  productSlug={props.match.params.productSlug}
                  id={props.match.params.id}
                  onCartData={handleCartData}
                />
              )}
            />

            {/* Following product layouts only for demonstration. */}
            <Route exact path="/shop/cart" component={CartPage} />
            <Route exact path="/shop/checkout" component={CartCheckout} />
            <Route exact path="/shop/checkout/success/:orderId" component={ShopPageOrderSuccess} />
            <Route exact path="/shop/wishlist" component={PageWishlist} />
            <Route exact path="/shop/compare" component={PageCompare} />
            <Route exact path="/shop/track-order" component={ShopPageTrackOrder} />
            {/* <Route exact path="/shop/checkout" component={PageCheckout} /> */}
            {/* <Route exact path="/shop/cart" component={PageCart} /> */}

            {/* Blog */}
            <Redirect exact from="/blog" to="/blog/category-classic" />
            <Route
              exact
              path="/blog/category-classic"
              render={(props) => <BlogPageCategory {...props} layout="classic" sidebarPosition="end" />}
            />
            <Route
              exact
              path="/blog/category-grid"
              render={(props) => <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />}
            />
            <Route
              exact
              path="/blog/category-list"
              render={(props) => <BlogPageCategory {...props} layout="list" sidebarPosition="end" />}
            />
            <Route
              exact
              path="/blog/category-left-sidebar"
              render={(props) => <BlogPageCategory {...props} layout="classic" sidebarPosition="start" />}
            />
            <Route
              exact
              path="/blog/post-classic"
              render={(props) => <BlogPagePost {...props} layout="classic" sidebarPosition="end" />}
            />
            <Route exact path="/blog/post-full" render={(props) => <BlogPagePost {...props} layout="full" />} />

            {/* Account */}
            <Route exact path="/account/login" component={AccountPageLogin} />
            <Route path="/account" component={AccountLayout} />

            {/* Site */}
            <Redirect exact from="/site" to="/site/about-us" />
            <Route exact path="/site/about-us" component={SitePageAboutUs} />
            <Route exact path="/site/components" component={SitePageComponents} />
            <Route exact path="/site/contact-us" component={SitePageContactUs} />
            <Route exact path="/site/contact-us-alt" component={SitePageContactUsAlt} />
            <Route exact path="/site/not-found" component={SitePageNotFound} />
            <Route exact path="/site/faq" component={SitePageFaq} />
            <Route exact path="/site/terms" component={SitePageTerms} />
            <Route exact path="/site/typography" component={SitePageTypography} />
            <Route exact path="/site/storeLocation" component={StoreLocation} />
            <Route exact path="/site/questions-page" component={QuestionsPage} />
            <Route exact path="/site/terms-and-conditions" component={TermsAndConditions} />
            <Route exact path="/site/local" component={Local} />
            <Route exact path="/site/returns-policy" component={ReturnsPolicy} />
            <Route exact path="/site/order-add" component={OrderAdd} />
            <Route exact path="/site/product-recall" component={ProductRecall} />
            <Route exact path="/site/delivery-guarantee" component={DeliveryInsurance} />
            <Route exact path="/site/pick-up-order" component={PickUpOrder} />
            <Route exact path="/site/referringFriend" component={ReferringFriend} />
            <Route exact path="/site/reward-program" component={RewardProgram} />
            <Route exact path="/site/order-confirm" component={OrderConfirm} />

            {/* Not in use */}
            {/* <Route exact path="/site/password-forget" component={PasswordForget} /> */}
            {/* <Route exact path="/site/reset-password" component={ResetPassword} /> */}

            {/* Page Not Found */}
            <Route component={SitePageNotFound} />
          </Switch>
        </div>

        <footer className="site__footer">
          <Footer />
        </footer>
      </div>
      {/* modal */}
      <AccountLogin />
    </React.Fragment>
  );
}

Layout.propTypes = {
  /**
   * header layout (default: 'classic')
   * one of ['classic', 'compact']
   */
  headerLayout: PropTypes.oneOf(['default', 'compact']),
  /**
   * home component
   */
  homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
  headerLayout: 'default',
};

export default Layout;

// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

export default function FooterContacts() {
  return (
    <div className="site-footer__widget footer-contacts">
      <h5 className="footer-contacts__title">Contact Us</h5>

      <div className="footer-contacts__text">
        Artisanal selection of the finest meats, cheeses, and grab-and go meals. Are we missing something? We would love
        to hear from you.
      </div>

      <ul className="footer-contacts__contacts">
        <li>
          <i className="footer-contacts__icon fas fa-globe-americas" />
          {theme.contacts.address}
        </li>
        <li>
          <i className="footer-contacts__icon far fa-envelope" />
          {theme.contacts.email}
        </li>
        <li>
          <i className="footer-contacts__icon fas fa-mobile-alt" />
          {`${theme.contacts.phone}`}
        </li>
        <li>
          <i className="footer-contacts__icon far fa-clock" />
          Mon-Sun 06:00AM - 7:00PM EST
        </li>
      </ul>
    </div>
  );
}

// react
import React from 'react';

export default function BlockMap() {
  return (
    <div className="block-map block">
      <div className="block-map__body">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2820.322612908317!2d-79.62050802473107!3d45.01837606307567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d2af3ac0af40ed3%3A0xa30c42826fa99aaf!2s3173%20Muskoka%20District%20Road%20169%2C%20Bala%2C%20ON%20P0C%201A0%2C%20Canada!5e0!3m2!1sen!2sin!4v1685102640332!5m2!1sen!2sin"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        />
      </div>
    </div>
  );
}

import React from 'react';

const OrderConfirm = () => {
  return (
    <>
      {/* <div className="order-confirm">
                <div className="container-fluid">
                    <h1>
                        ORDER CONFIRMATION
                    </h1>
                    <div className="user-confirm-order">
                        <h4 className="user-id">
                            Hello John Doe,
                        </h4>
                        <h4>
                            Great food is heading your way! The <span>Cottage Butcher</span> is working on your order now and will <span>deliver</span> within your specified timeline. Here are you order details:
                        </h4>
                    </div>

                    <table className="table" style={{ marginBottom: "0" }}>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "20px", color: "#716E6C" }}>
                                    Order Number
                                </td>
                                <td style={{ fontSize: "20px", color: "#000000", fontWeight: "600" }}>
                                    #535
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "20px", color: "#716E6C" }}>
                                    Order Type
                                </td>
                                <td style={{ fontSize: "20px", color: "#000000", fontWeight: "600" }}>
                                    Delivery
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "20px", color: "#716E6C" }}>
                                    Address
                                </td>
                                <td style={{ fontSize: "20px", color: "#000000", fontWeight: "600" }}>
                                    H.No. 35, Bala, ON, Canada. P0ACX0A
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "20px", color: "#716E6C" }}>
                                    Order Amount
                                </td>
                                <td style={{ fontSize: "20px", color: "#000000", fontWeight: "600" }}>
                                    $354.30 CAD
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "20px", color: "#716E6C" }}>
                                    Shipping
                                </td>
                                <td style={{ fontSize: "20px", color: "#000000", fontWeight: "600" }}>
                                    $0.00 CAD
                                </td>
                            </tr>
                            <tr style={{ borderBottom: "1px solid transparent" }}>
                                <td style={{ fontSize: "20px", color: "#716E6C", }}>
                                    Any other details….
                                </td>
                                <td style={{ fontSize: "20px", color: "#000000", fontWeight: "600" }}>

                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <p className="issues">
                        If you have any issues or questions, you can call the store at 250-589-5051.
                    </p>
                    <h5 className="business">
                        Thanks for being the best part of our business, and we look forward to serving you again.
                    </h5>
                    <div className="last-logo">
                        <img src="../images/images/logo.svg" alt="" />
                    </div>
                </div>
            </div> */}
      <table style={{ maxWidth: '800px', width: '100%', margin: '50px auto', backgroundColor: '#F8F6F4' }}>
        <thead>
          <tr>
            <th colSpan="2">
              <h1
                style={{
                  textAlign: 'center',
                  fontSize: '30px',
                  color: '#BA906D',
                  marginBottom: '0',
                  padding: '90px 0 65px',
                }}
              >
                ORDER CONFIRMATION
              </h1>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2">
              <h3
                style={{
                  fontSize: '24px',
                  color: '#000000',
                  marginBottom: '0',
                  fontWeight: '400',
                  padding: '0 70px 35px',
                }}
              >
                Hello John Doe,
              </h3>
              <h3
                style={{
                  fontSize: '24px',
                  color: '#000000',
                  marginBottom: '0',
                  fontWeight: '400',
                  padding: '0 70px 50px',
                }}
              >
                Great food is heading your way! The Cottage Butcher is working on your order now and will deliver within
                your specified timeline. Here are you order details:
              </h3>
            </td>
          </tr>
          <tr>
            <p style={{ padding: '0 70px', marginBottom: '0' }}>
              <table className="table order-table mb-0">
                <tbody>
                  <tr>
                    <td style={{ color: '#716E6C', fontSize: '20px' }}>Order Number</td>
                    <td style={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>#535</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#716E6C', fontSize: '20px' }}>Order Type</td>
                    <td style={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>Delivery</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#716E6C', fontSize: '20px' }}>Address</td>
                    <td style={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>
                      H.No. 35, Bala, ON, Canada. P0ACX0A
                    </td>
                  </tr>
                  <tr>
                    <td style={{ color: '#716E6C', fontSize: '20px' }}>Order Amount</td>
                    <td style={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>$354.30 CAD</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#716E6C', fontSize: '20px' }}>Shipping</td>
                    <td style={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>$0.00 CAD</td>
                  </tr>
                  <tr style={{ borderBottom: '1px solid transparent' }}>
                    <td style={{ color: '#716E6C', fontSize: '20px' }}>Any other details….</td>
                    <td style={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}></td>
                  </tr>
                  <tr style={{ borderBottom: '1px solid transparent' }}>
                    <td colSpan="2">
                      <p
                        style={{
                          paddingTop: '40px',
                          fontSize: '19px',
                          fontStyle: 'italic',
                          color: '#000000',
                          textAlign: 'center',
                        }}
                      >
                        If you have any issues or questions, you can call the store at 250-589-5051.
                      </p>
                    </td>
                  </tr>
                  <tr style={{ borderBottom: '1px solid transparent' }}>
                    <td colSpan="2">
                      <h3
                        style={{
                          fontSize: '24px',
                          color: '#000000',
                          marginBottom: '0',
                          fontWeight: '400',
                          textAlign: 'center',
                        }}
                      >
                        Thanks for being the best part of our business, and we look forward to serving you again.
                      </h3>
                    </td>
                  </tr>
                  <tr style={{ borderBottom: '1px solid transparent' }}>
                    <td colSpan="2">
                      <p style={{ textAlign: 'center', padding: '65px 0 80px', marginBottom: '0' }}>
                        <img src="../images/images/logo.svg" alt="" style={{ width: '200px' }} />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default OrderConfirm;

// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// blocks
import BlockLoader from '../blocks/BlockLoader';

// data stubs
import { API_GET_PRODUCTS } from '../../config/endpoints';
import { getMediaUrl, getProductText, ShowToast } from '../../services/helpers';
import AddToCart from '../widgets/AddToCart';
import GroupProduct from './GroupProduct';

function ProductView(props) {
  const { productSlug, layout, sidebarPosition, id, onCartData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({ attributes: [] });
  const [productDetails, setProductDetails] = useState({ price: { attributes: [] } });
  const [met, setMeta] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [latestProducts, setLatestProducts] = useState([]);
  const [selectedQty, setSelectedQty] = useState(1);

  // Load product.
  useEffect(() => {
    setIsLoading(true);
    fetch(
      API_GET_PRODUCTS +
        '?id=' +
        id +
        '&include=attribute,media,price,product,product/property,text,catalog,supplier,stock',
      {
        method: 'GET',
        crossDomain: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setProduct(data.data);
        // setCategoriesList(data.included);
        setIsLoading(false);
        renderProductDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const renderProductDetails = (product) => {
    setProductDetails(getProductText(product));
  };

  if (isLoading) {
    return <BlockLoader />;
  }

  return (
    <React.Fragment>
      <Helmet>{/* <title>{`${product.name} — ${theme.name}`}</title> */}</Helmet>

      {/* <PageHeader breadcrumb={breadcrumb} /> */}
      <div className="container">
        <div className="product product--layout--standard">
          <div className="product__content">
            <div className="product__gallery">
              <div className="product-gallery">
                <div className="product-gallery__featured">
                  <button type="button" className="product-gallery__zoom d-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
                      <path d="M15,18c-2,0-3.8-0.6-5.2-1.7c-1,1.3-2.1,2.8-3.5,4.6c-2.2,2.8-3.4,1.9-3.4,1.9s-0.6-0.3-1.1-0.7 c-0.4-0.4-0.7-1-0.7-1s-0.9-1.2,1.9-3.3c1.8-1.4,3.3-2.5,4.6-3.5C6.6,12.8,6,11,6,9c0-5,4-9,9-9s9,4,9,9S20,18,15,18z M15,2 c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S18.9,2,15,2z M16,13h-2v-3h-3V8h3V5h2v3h3v2h-3V13z" />
                    </svg>
                  </button>
                  <div className="slick-prevent-click">
                    <div className="slick-slider slick-initialized" dir="ltr">
                      <div className="slick-list">
                        <div
                          className="slick-track"
                          // style={{ width: '1062px', opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}
                        >
                          <div
                            data-index={0}
                            className="slick-slide slick-active slick-current"
                            tabIndex={-1}
                            aria-hidden="false"
                            // style={{ outline: 'none', width: '531px' }}
                          >
                            <div>
                              <div
                                dir="ltr"
                                className="correct-slick-active"
                                tabIndex={-1}
                                // style={{ width: '100%', display: 'inline-block' }}
                              >
                                <div className="product-image product-image--location--gallery">
                                  <a className="product-image__body" href="javascript:;">
                                    <img
                                      className="product-image__img"
                                      src={getMediaUrl(productDetails.media, true)}
                                      alt=""
                                      // data-width={700}
                                      // data-height={700}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product__info">
              <h1 className="product__name">
                {productDetails.name}
                {/* Polenta and cured meats */}
              </h1>
              <div className="product__description">{productDetails.shortDescription}</div>
              <ul className="product__meta">
                <li className="product__meta-availability">
                  Availability:{' '}
                  {productDetails?.stock?.attributes['stock.stocklevel'] !== 0 ? (
                    <span className="text-success fw-bolder">In Stock</span>
                  ) : (
                    <span className="text-danger fw-bolder">Not Available</span>
                  )}
                </li>
              </ul>
            </div>
            <div className="product__sidebar">
              <div className="product__availability">
                Availability:{' '}
                {productDetails?.stock?.attributes['stock.stocklevel'] !== 0 ? (
                  <span className="text-success fw-bolder">In Stock</span>
                ) : (
                  <span className="text-danger fw-bolder">Not Available</span>
                )}
              </div>
              {product.attributes['product.type'] !== 'group' ? (
                <>
                  <div className="product__prices">
                    ${productDetails.price ? productDetails.price.attributes['price.value'] : ''}
                  </div>
                  {productDetails?.stock?.attributes['stock.stocklevel'] !== 0 ? (
                    <form className="product__options">
                      <div className="form-group product__option">
                        <label htmlFor="product-quantity" className="product__option-label">
                          Quantity
                        </label>
                        <div className="product__actions">
                          <div className="product__actions-item">
                            <div className="input-number product__quantity">
                              <input
                                className="form-control input-number__input form-control-lg"
                                type="number"
                                id="product-quantity"
                                aria-label="Quantity"
                                min={1}
                                step={1}
                                defaultValue={selectedQty}
                                value={selectedQty}
                              />
                              <div className="input-number__add" onClick={(e) => setSelectedQty(selectedQty + 1)} />
                              <div
                                className="input-number__sub"
                                onClick={selectedQty === 1 ? null : (e) => setSelectedQty(selectedQty - 1)}
                              />
                            </div>
                          </div>
                          <div className="product__actions-item product__actions-item--addtocart">
                            {/* <button type="button" className="btn btn-primary btn-lg" onClick={(e) => handleAddToCart(e)}>
                        Add to cart
                      </button> */}
                            <AddToCart product={productDetails} selectedQty={selectedQty} onUpdateCart={onCartData} />
                          </div>
                          {/* <div className="product__actions-item product__actions-item--wishlist">
                      <button
                        type="button"
                        data-toggle="tooltip"
                        title="Wishlist"
                        className="btn btn-secondary btn-svg-icon btn-lg"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                          <path d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7 l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                        </svg>
                      </button>
                    </div> */}
                          <div className="product__actions-item product__actions-item--compare d-none">
                            <button
                              type="button"
                              data-toggle="tooltip"
                              title="Compare"
                              className="btn btn-secondary btn-svg-icon btn-lg"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                                <path d="M9,15H7c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v12C10,14.6,9.6,15,9,15z" />
                                <path d="M1,9h2c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1v-4C0,9.4,0.4,9,1,9z" />
                                <path d="M15,5h-2c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <h5 className="fw-bolder mt-5">Out Of Stock</h5>
                  )}
                </>
              ) : (
                <GroupProduct
                  product={product}
                  basePrice={productDetails.price ? productDetails.price.attributes['price.value'] : ''}
                  onCartData={onCartData}
                />
              )}

              {/* {product.attributes['product.type'] === 'group' ? <GroupProduct product={product} /> : null} */}
            </div>
            <div className="product__footer">
              <div className="product__tags tags d-none">
                <div className="tags__list">
                  <a href="/">Mounts</a>
                  <a href="/">Electrodes</a>
                  <a href="/">Chainsaws</a>
                </div>
              </div>
              <div className="product__share-links share-links">
                <ul className="share-links__list">
                  <li className="share-links__item share-links__item--type--like">
                    <a href="/">Like</a>
                  </li>
                  <li className="share-links__item share-links__item--type--tweet">
                    <a href="/">Tweet</a>
                  </li>
                  <li className="share-links__item share-links__item--type--pin">
                    <a href="/">Pin It</a>
                  </li>
                  <li className="share-links__item share-links__item--type--counter">
                    <a href="/">4K</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="product-tabs d-none">
          <div className="product-tabs__list">
            <button type="button" className="product-tabs__item product-tabs__item--active">
              Description
            </button>
            <button type="button" className="product-tabs__item">
              Reviews
            </button>
          </div>
          <div className="product-tabs__content">
            <div className="product-tabs__pane product-tabs__pane--active">
              <div className="typography">
                <h3>Product Full Description</h3>
                {productDetails.longDescription}
              </div>
            </div>
            <div className="product-tabs__pane"></div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}

ProductView.propTypes = {
  /** Product slug. */
  productSlug: PropTypes.string,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
  /**
   * sidebar position (default: 'start')
   * one of ['start', 'end']
   * for LTR scripts "start" is "left" and "end" is "right"
   */
  sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ProductView.defaultProps = {
  layout: 'standard',
  sidebarPosition: 'start',
};

export default ProductView;

import React from 'react';

const ProductRecall = () => {
  return (
    <>
      <div className="page-header">
        <div className="page-header__container container">
          <div className="page-header__breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                  {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                    <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                  </svg>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                <li className="breadcrumb-item active">
                  <a href="/product-recall">Product Recall</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="product-recall">
        <div className="container">
          <div className="row">
            <h1 className="mb-3">Product Recall</h1>
            <h5>Recalls & Product Notices</h5>
            <p>
              Look here for future information on product recalls, product notices, etc. If we get notified of a product
              recall or any updated information on products you have purchased from The Cottage Butcher, you will find
              the notice here or in your Account log-in under Notifications.
            </p>
            <p>There are no recalls or product notices at this time.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRecall;

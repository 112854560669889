import React from 'react';

const TermsAndConditions = () => {
  return (
    <>
      <div className="page-header">
        <div className="page-header__container container">
          <div className="page-header__breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                  {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                    <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                  </svg>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                <li className="breadcrumb-item active">
                  <a href="/terms-and-conditions">Terms and Conditions</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="conditions mt-0">
        <div className="container">
          <h1 className="mb-3 ">Terms and Conditions</h1>

          <h5 className="mt-4">COOKIE COLLECTION AND UPDATED PRIVACY COMMITMENT</h5>
          <p>
            The Cottage Butcher continuously makes updates to our data and privacy commitment We collecting cookies to
            provide you with offers and on-site navigation tailored to your interest. For more information about cookies
            and how to disable cookies, visit our privacy commitment. Learn more
          </p>

          <h5>PRIVACY POLICY</h5>
          <p>
            At The Cottage Butcher, we are committed to protecting the privacy and security of information you share
            with us. The Cottage Butcher and its direct and indirect subsidiaries (wholly or partially owned), parents,
            and affiliates, including each of their owned, operated or affiliated banners, brands or divisions
            (collectively, “Cottage butcher”, “we”, “us” and “our”) respect the privacy of each customer and prospective
            customer (“Customer” or “you”). The term “Customer” also includes persons who visit our websites, use our
            mobile apps, subscribe to our e-mail communications, enter into one of our contests, correspond or
            communicate with us, have enrolled, or are applying for enrolment, in any rewards or similar program of
            which The Cottage Butcher is a sponsor or participant. The Cottage Butcher is committed to protecting all
            personal information collected from Customers. This privacy policy has been compiled to better serve those
            who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as
            described in Canadian privacy law and information security, is information that can be used on its own or
            with other information to identify, contact, or locate a single person, or to identify an individual in
            context.
          </p>
          <h5>Information we collect</h5>
          <p>
            We will not collect any personal information about you except when specifically and knowingly provided by
            you. We collect information from you when you register on our site, subscribe to a newsletter, make a
            purchase, respond to a survey, fill out a form, enter other information on our site.
          </p>
          <h5>How we use information</h5>
          <p>We may use the information you provide in order to:</p>
          <ul>
            <li>
              Personalize your experience and to allow us to deliver the type of content and product offerings in which
              you are most interested.
            </li>
            <li>Improve our website in order to better serve you.</li>
            <li>Allow us to better service you in responding to your customer service requests.</li>
            <li>Administer a contest, promotion, survey or other site feature.</li>
            <li>Follow up after correspondence (email or phone inquiries)</li>
          </ul>
          <h5>How we protect information</h5>
          <p>
            Our website is scanned on a regular basis for security risks and known vulnerabilities in order to make your
            visit to our site as safe as possible. Your personal information is contained behind secured networks and is
            only accessible by a limited number of persons who have special access rights to such systems, and are
            required to keep the information confidential. In addition, all sensitive/credit information you supply is
            encrypted via Secure Socket Layer (SSL) technology. We implement a variety of security measures when a user
            places an order, enters, submits, or accesses information to maintain the safety of personal information.
            All transactions are processed through a gateway provider and are not stored or processed on our servers.
          </p>
          <h5>How we use cookies</h5>
          <p>
            Cookies are small files that a site or its service provider transfers to your computer's hard drive through
            your Web browser (if you allow) that enables the site's or service provider's systems to recognize your
            browser and capture and remember certain information. For instance, we use cookies to help us remember and
            process the items in your shopping cart. They are also used to help us understand your preferences based on
            previous or current site activity, which enables us to provide you with improved services. We also use
            cookies to help us compile aggregate data about site traffic and site interaction so that we can offer
            better site experiences and tools in the future.
          </p>
          <h5>We use cookies to:</h5>
          <ul>
            <li>Help remember and process the items in the shopping cart.</li>
            <li>Understand and save user's preferences for future visits.</li>
            <li>
              Compile aggregate data about site traffic and site interactions in order to offer better site experiences
              and tools in the future. We may also use trusted third-party services that track this information on our
              behalf.
            </li>
          </ul>
          <p>
            You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn
            off all cookies. You do this through your browser settings. Since each browser is a little different, look
            at your browser's Help Menu to learn the correct way to modify your cookies.
          </p>
          <p>
            If you turn cookies off, some features will be disabled. Some of the features that make your site experience
            more efficient and may not function properly, like automatic login.
          </p>
          <p>However, you will still be able to place orders over the telephone by contacting customer service.</p>
          <h5>How we work with third-parties</h5>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information
            unless we provide users with advance notice. This does not include website hosting partners and other
            parties who assist us in operating our website, conducting our business, or serving our users, so long as
            those parties agree to keep this information confidential. We may also release information when it's release
            is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights,
            property or safety.
          </p>
          <h5>Google & Tracking</h5>
          <p>We use Google Analytics on our website.</p>
          <p>
            Users can visit our site anonymously. We also honor Do Not Track signals and Do Not Track, plant cookies, or
            use advertising when a Do Not Track (DNT) browser mechanism is in place.
          </p>
          <p>COPPA (Children Online Privacy Protection Act)</p>
          <p>
            We do not specifically market to children under the age of 13 years old. If we become aware that we have
            collected personally identifiable information from someone under the age of thirteen, we will remove that
            child’s personal information from our database.
          </p>
          <h5>Email communications</h5>
          <p>
            We comply with Canada's Anti-Spam Law (or "CASL"), a law that sets the rules for commercial email,
            establishes requirements for commercial messages, gives recipients the right to have emails stopped from
            being sent to them, and spells out tough penalties for violations.
          </p>
          <p>We collect your email address in order to:</p>
          <ul>
            <li>Send information, respond to inquiries, and/or other requests or questions</li>
            <li>Process orders and to send information and updates pertaining to orders.</li>
            <li>Send you additional information related to your product and/or service</li>
            <li>
              Market to our mailing list or continue to send emails to our clients after the original transaction has
              occurred.
            </li>
          </ul>
          <h5>To be in accordance with CASL, we agree to the following</h5>
          <ul>
            <li>Not use false or misleading subjects or email addresses.</li>
            <li>Identify the message as an advertisement in some reasonable way.</li>
            <li>Include the physical address of our business or site headquarters.</li>
            <li>Monitor third-party email marketing services for compliance, if one is used.</li>
            <li>Honor opt-out/unsubscribe requests quickly.</li>
            <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
          </ul>
          <p>
            If at any time you would like to unsubscribe from receiving future emails, you can email us at
            info@cottagebutcher.com or follow the instructions at the bottom of each email and we will promptly remove
            you from all correspondence.
          </p>
          <h5>Communication via SMS text</h5>
          <p>
            In addition to email, we can send SMS text messages to phone numbers to alert customers or potential
            customers of deliveries, sales, events, and other communications. Recipients of SMS text messages can opt
            out at any time by contacting customer service or responding “STOP” to The Cottage Butcher generated SMS
            text message.
          </p>
          <h5>Contacting Us</h5>
          <p>
            If there are any questions regarding this privacy policy, you may contact us using the information below:
          </p>
          <p>
            Email: <a href="mailto:info@cottagebutcher.com">info@cottagebutcher.com</a>
          </p>
          <p>Mailing Address</p>
          <p>3173 Muskoka District Road 169, Bala, ON P0C 1A0, Canada</p>

          <p>Phone: 705 762 5995</p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;

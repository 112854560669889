import React from 'react';

const ReturnsPolicy = () => {
  return (
    <>
      <div className="page-header">
        <div className="page-header__container container">
          <div className="page-header__breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                  {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                    <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                  </svg>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                <li className="breadcrumb-item active">
                  <a href="/returns-policy">Returns Policy</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="delivery-and-returns">
        <div className="container">
          <div className="row">
            <h1 className="mb-3">Delivery and Returns Policy</h1>
            <h5>Please save your receipt.</h5>
            <p>
              Because all of many of our products are perishable, returns are limited to unopened products. However, we
              will try our best to make it right if you have any issue with your box.
            </p>
            <p>
              We will gladly exchange or refund most items with a receipt dated within 30 days of purchase. Some rules
              or exceptions may apply. If you have questions or concerns, please contact the Cottage Butcher Customer
              Service here.
            </p>
            <p>Receipted refunds: Returns will be issued based on original method of payment.</p>
            <p>
              Gift Receipt Returns: Returns accompanied by a gift receipt will be extended in the form of a Cottage
              Butcher gift card. The refund value will reflect any discounts applied at time of original purchase. All
              rules and restrictions apply.
            </p>
            <p>
              Damages and issues: Please inspect your order upon reception and contact us immediately if the item is
              defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.
            </p>
            <p>
              Pre-Paid/Services/Clearance Items: Refunds and exchanges are not permitted for Gift Cards, event tickets,
              licenses, permits, postage stamps, clearance items, etc. All sales are final.
            </p>
            <p>
              Seasonal Items: May be returned within 10 days of purchase with original packaging. Seasonal items include
              holiday merchandise, most home and kitchen items and assorted accessories.
            </p>
            <p>
              Discounts and Promotions: The refunded value for each item returned will be reduced to reflect the value
              of any free gift or discount.
            </p>
            <p>
              Exceptions / non-returnable items: Certain types of items cannot be returned, like perishable goods (such
              as food, flowers, or plants), custom products (such as special orders or personalized items), and personal
              care goods (such as cooking products). We also do not accept returns for hazardous materials, flammable
              liquids, or gases. Please get in touch if you have questions or concerns about your specific item.
            </p>
            <p>
              No Receipt Returns/Exchanges: A valid government issued ID is required for all non‑receipted
              returns/exchanges. Refund will be extended in the form of a Cottage Butcher gift card or Cut Above Rewards
              member account credit.
            </p>
            <h5>Can't find your receipt?</h5>
            <p>
              If you are a Cut Above member, we will be able to look up your purchase. In most cases, if you paid with
              an electronic payment type (credit or debit), we can locate your purchase and process your no‑receipt
              return as a receipted refund with our receipt look‑up feature.
            </p>
            <p>The Cottage Butcher reserves the right to limit or refuse any refund.</p>
            <p>Other restrictions may apply.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnsPolicy;

// react
import React, { useEffect, useMemo, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import shopApi from '../../api/shop';
import { useProductTabs } from '../../services/hooks';

// blocks
import BlockBanner from '../blocks/BlockBanner';
import BlockBrands from '../blocks/BlockBrands';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
import { API_GET_PRODUCTS } from '../../config/endpoints';
import { getProductDetailsMultiple } from '../../services/helpers';
import AddToCart from '../widgets/AddToCart';

function HomePageTwo(props) {
  const { onCartData } = props;
  const [bestSelllersList, setBestSelllersList] = useState({ data: [] });

  const getBestSellers = () => {
    fetch(
      API_GET_PRODUCTS +
        '?sort=product%2Elabel&filter[f_catid]=' +
        process.env.REACT_APP_BESTSELLER_ID +
        '&include=media,price',
      {
        method: 'GET',
        crossDomain: true,
        credentials: 'include',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setBestSelllersList(data);
        // setCategoriesList(data.included);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBestSellers();
  }, []);

  /**
   * Featured products.
   */
  // const featuredProducts = useProductTabs(
  //   useMemo(
  //     () => [
  //       { id: 1, name: 'All', categorySlug: undefined },
  //       { id: 2, name: 'Power Tools', categorySlug: 'power-tools' },
  //       { id: 3, name: 'Hand Tools', categorySlug: 'hand-tools' },
  //       { id: 4, name: 'Plumbing', categorySlug: 'plumbing' },
  //     ],
  //     []
  //   ),
  //   (tab) => shopApi.getPopularProducts({ limit: 12, category: tab.categorySlug })
  // );

  /**
   * Bestsellers.
   */
  // const bestsellers = useDeferredData(() => shopApi.getPopularProducts({ limit: 7 }), []);

  /**
   * Latest products.
   */
  // const latestProducts = useProductTabs(
  //   useMemo(
  //     () => [
  //       { id: 1, name: 'All', categorySlug: undefined },
  //       { id: 2, name: 'Power Tools', categorySlug: 'power-tools' },
  //       { id: 3, name: 'Hand Tools', categorySlug: 'hand-tools' },
  //       { id: 4, name: 'Plumbing', categorySlug: 'plumbing' },
  //     ],
  //     []
  //   ),
  //   (tab) => shopApi.getLatestProducts({ limit: 8, category: tab.categorySlug })
  // );

  /**
   * Product columns.
   */
  // const columns = useProductColumns(
  //   useMemo(
  //     () => [
  //       {
  //         title: 'Top Rated Products',
  //         source: () => shopApi.getTopRatedProducts({ limit: 3 }),
  //       },
  //       {
  //         title: 'Special Offers',
  //         source: () => shopApi.getDiscountedProducts({ limit: 3 }),
  //       },
  //       {
  //         title: 'Bestsellers',
  //         source: () => shopApi.getPopularProducts({ limit: 3 }),
  //       },
  //     ],
  //     []
  //   )
  // );

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Home`}</title>
      </Helmet>

      {useMemo(
        () => (
          <BlockSlideShow />
        ),
        []
      )}

      {useMemo(
        () => (
          <BlockFeatures layout="boxed" />
        ),
        []
      )}

      {/* {useMemo(
        () => (
          <BlockProductsCarousel
            title="Featured Products"
            layout="grid-5"
            rows={2}
            products={featuredProducts.data}
            loading={featuredProducts.isLoading}
            groups={featuredProducts.tabs}
            onGroupClick={featuredProducts.handleTabChange}
          />
        ),
        [featuredProducts]
      )} */}

      {useMemo(
        () => (
          <BlockBanner />
        ),
        []
      )}
      <div className="all-dishes">
        <div className="container">
          <div className="block-header ">
            <h3 className="block-header__title">Butcher Recommended</h3>
            <div className="block-header__divider" />
          </div>
          <div className="row align-items-center">
            <div className="col-md-4">
              <a href="/shop/category/34/drinks" className="product-items">
                <img src="../../../images/icon/drinks.svg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4">
              <a href="/shop/category/50/meat" className="product">
                <img src="../../../images/icon/meats.svg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4">
              <a href="/shop/category/74/seafood" className="product-items-list">
                <img src="../../../images/icon/fish.svg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4">
              <a href="/shop/category/72/pantry" className="product-items">
                <img src="../../../images/icon/pantry.svg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4">
              <a href="/shop/category/70/baked-goods" className="product">
                <img src="../../../images/icon/mixbox.svg" alt="" className="img-fluid" style={{ maxWidth: '150px' }} />
              </a>
            </div>
            <div className="col-md-4">
              <a href="/shop/category/37/cheese" className="product-items-list">
                <img src="../../../images/icon/cheese.svg" alt="" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* old butcher recommended */}
      <div className="container d-none">
        <div className="block-header ">
          <h3 className="block-header__title">Butcher Recommended</h3>
          <div className="block-header__divider" />
        </div>
        <div className="block-products__body">
          <div className="block-products__list">
            {bestSelllersList.data.map((item, key) => (
              <>
                <div key={key} className="block-products__list-item">
                  <div className="product-card product-out-of-stock">
                    <button type="button" className="product-card__quickview d-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                        <path d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z  M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z" />
                      </svg>
                    </button>
                    <div className="product-card__image product-image">
                      <a className="product-image__body" href={`/product/${item.id}/${item.attributes['product.url']}`}>
                        <img
                          className="product-image__img"
                          src={
                            getProductDetailsMultiple(bestSelllersList, item).media
                              ? getProductDetailsMultiple(bestSelllersList, item).media
                              : 'images/products/product-7.jpg'
                          }
                          alt=""
                        />
                      </a>
                    </div>
                    <a href={`/product/${item.id}/${item.attributes['product.url']}`} className="product-card__link">
                      {item.attributes['product.label']}
                      <div className="product-card__info">
                        <a
                          href={`/product/${item.id}/${item.attributes['product.url']}`}
                          className="product-card__link"
                        >
                          {/* {item.attributes['product.label']} */}
                          <div className="product-card__name">
                            <div class="product-card__rating"></div>
                          </div>
                        </a>
                      </div>

                      <div className="product-card__actions">
                        <div className="product-card__prices">
                          $
                          {getProductDetailsMultiple(bestSelllersList, item).price
                            ? getProductDetailsMultiple(bestSelllersList, item).price.attributes['price.value']
                            : '-'}
                        </div>
                        <div className="product-card__buttons">
                          {/* <button type="button" className="btn btn-primary product-card__addtocart">
                          Add To Cart
                        </button> */}
                          {/* <AddToCart product={item} selectedQty={1} onUpdateCart={onCartData} /> */}
                          <AddToCart product={item} selectedQty={1} onUpdateCart={onCartData} />
                          {/* <button
                          type="button"
                          className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                            <path d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7 l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                          </svg>
                        </button> */}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="block-products__featured">
            <div className="block-products__featured-item">
              <div className="product-card">
                <button type="button" className="product-card__quickview">
                  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                    <path d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z  M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z" />
                  </svg>
                </button>
                <div className="product-card__image product-image">
                  <a className="product-image__body" href="/shop/products/brandix-pliers">
                    <img className="product-image__img" src="images/products/product-6.jpg" alt="" />
                  </a>
                </div>
                <div className="product-card__info">
                  <div className="product-card__name">
                    <a href="/shop/products/brandix-pliers">Brandix Plierss</a>
                  </div>
                  <ul className="product-card__features-list">
                    <li>Speed: 750 RPM</li>
                    <li>Power Source: Cordless-Electric</li>
                    <li>Battery Cell Type: Lithium</li>
                    <li>Voltage: 20 Volts</li>
                    <li>Battery Capacity: 2 Ah</li>
                  </ul>
                </div>
                <div className="product-card__actions">
                  <div className="product-card__availability">
                    Availability: <span className="text-success">In Stock</span>
                  </div>
                  <div className="product-card__prices">$24.00</div>
                  <div className="product-card__buttons">
                    <button type="button" className="btn btn-primary product-card__addtocart">
                      Add To Cart
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary product-card__addtocart product-card__addtocart--list"
                    >
                      Add To Cart
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                        <path d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7 l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare d-none"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                        <path d="M9,15H7c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v12C10,14.6,9.6,15,9,15z" />
                        <path d="M1,9h2c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1v-4C0,9.4,0.4,9,1,9z" />
                        <path d="M15,5h-2c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {useMemo(
        () => (
          <BlockProducts
            title="Bestsellers"
            layout="large-last"
            featuredProduct={bestsellers.data[0]}
            products={bestsellers.data.slice(1, 7)}
          />
        ),
        [bestsellers.data]
      )} */}

      {/* {useMemo(
        () => (
          <BlockCategories title="Popular Categories" layout="compact" categories={categories} />
        ),
        []
      )} */}

      {/* {useMemo(
        () => (
          <BlockProductsCarousel
            title="New Arrivals"
            layout="grid-5"
            products={latestProducts.data}
            loading={latestProducts.isLoading}
            groups={latestProducts.tabs}
            onGroupClick={latestProducts.handleTabChange}
          />
        ),
        [latestProducts]
      )} */}

      {/* {useMemo(
        () => (
          <BlockPosts title="Latest Blogs" layout="grid-nl" posts={posts} />
        ),
        []
      )} */}

      {useMemo(
        () => (
          <BlockBrands />
        ),
        []
      )}
      <div className="block block-product-columns d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="block-header">
                <h3 className="block-header__title">Top Rated Products</h3>
                <div className="block-header__divider" />
              </div>
              <div className="block-product-columns__column">
                {bestSelllersList.data.map((item, key) => (
                  <div key={key} className="block-product-columns__item">
                    <div className="product-card product-card--layout--horizontal">
                      <button type="button" class="product-card__quickview">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                          <path d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z  M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z"></path>
                        </svg>
                      </button>
                      <div className="product-card__badges-list">
                        {/* <div className="product-card__badge product-card__badge--new">New</div> */}
                      </div>
                      <div className="product-card__image product-image">
                        {/* <a className="product-image__body" href="/shop/products/Polenta and cured meats"> */}
                        <a
                          href={`/product/${item.id}/${item.attributes['product.url']}`}
                          className="product-image__body"
                        >
                          <img
                            className="product-image__img"
                            src={
                              getProductDetailsMultiple(bestSelllersList, item).media
                                ? getProductDetailsMultiple(bestSelllersList, item).media
                                : 'images/products/product-7.jpg'
                            }
                            alt=""
                          />
                          {/* </a> */}
                        </a>
                      </div>
                      <a
                        href={`/product/${item.id}/${item.attributes['product.url']}`}
                        className="product-card__title_name"
                      >
                        {item.attributes['product.label']}
                        <div className="product-card__info">
                          <div className="product-card__name">
                            {/* <a
                              href={`/product/${item.id}/${item.attributes['product.url']}`}
                              className="product-card__title_name"
                            >
                              {item.attributes['product.label']}
                            </a> */}
                          </div>
                          <div className="product-card__rating"></div>
                        </div>
                        <div className="product-card__actions">
                          <div className="product-card__availability">
                            Availability: <span className="text-success">In Stock</span>
                          </div>
                          <div className="product-card__prices">
                            $
                            {getProductDetailsMultiple(bestSelllersList, item).price
                              ? getProductDetailsMultiple(bestSelllersList, item).price.attributes['price.value']
                              : '-'}
                          </div>
                          <div className="product-card__buttons">
                            <button type="button" className="btn btn-primary product-card__addtocart">
                              Add To Cart
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary product-card__addtocart product-card__addtocart--list"
                            >
                              Add To Cart
                            </button>
                            <button
                              type="button"
                              className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                                <path d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7 l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                              </svg>
                            </button>
                            <button
                              type="button"
                              className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare d-none"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                                <path d="M9,15H7c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v12C10,14.6,9.6,15,9,15z" />
                                <path d="M1,9h2c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1v-4C0,9.4,0.4,9,1,9z" />
                                <path d="M15,5h-2c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="block-header ">
                <h3 className="block-header__title">Special Offers</h3>
                <div className="block-header__divider" />
              </div>
              <div className="block-product-columns__column">
                {bestSelllersList.data.map((item, key) => (
                  <div key={key} className="block-product-columns__item">
                    <div className="product-card product-card--layout--horizontal">
                      <button type="button" class="product-card__quickview">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                          <path d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z  M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z"></path>
                        </svg>
                      </button>
                      <div className="product-card__badges-list">
                        {/* <div className="product-card__badge product-card__badge--new">New</div> */}
                      </div>
                      <div className="product-card__image product-image">
                        {/* <a className="product-image__body" href="/shop/products/Polenta and cured meats"> */}
                        <a
                          href={`/product/${item.id}/${item.attributes['product.url']}`}
                          className="product-image__body"
                        >
                          <img
                            className="product-image__img"
                            src={
                              getProductDetailsMultiple(bestSelllersList, item).media
                                ? getProductDetailsMultiple(bestSelllersList, item).media
                                : 'images/products/product-7.jpg'
                            }
                            alt=""
                          />
                        </a>
                        {/* </a> */}
                      </div>
                      <a
                        href={`/product/${item.id}/${item.attributes['product.url']}`}
                        className="product-card__title_name"
                      >
                        {item.attributes['product.label']}
                        <div className="product-card__info">
                          <div className="product-card__name">
                            {/* <a
                              href={`/product/${item.id}/${item.attributes['product.url']}`}
                              className="product-card__title_name"
                            >
                              {item.attributes['product.label']}
                            </a> */}
                          </div>
                          <div className="product-card__rating"></div>
                        </div>
                        <div className="product-card__actions">
                          <div className="product-card__availability">
                            Availability: <span className="text-success">In Stock</span>
                          </div>
                          <div className="product-card__prices">
                            $
                            {getProductDetailsMultiple(bestSelllersList, item).price
                              ? getProductDetailsMultiple(bestSelllersList, item).price.attributes['price.value']
                              : '-'}
                          </div>
                          <div className="product-card__buttons">
                            <button type="button" className="btn btn-primary product-card__addtocart">
                              Add To Cart
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary product-card__addtocart product-card__addtocart--list"
                            >
                              Add To Cart
                            </button>
                            <button
                              type="button"
                              className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                                <path d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7 l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                              </svg>
                            </button>
                            <button
                              type="button"
                              className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare d-none"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                                <path d="M9,15H7c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v12C10,14.6,9.6,15,9,15z" />
                                <path d="M1,9h2c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1v-4C0,9.4,0.4,9,1,9z" />
                                <path d="M15,5h-2c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="block-header ">
                <h3 className="block-header__title">Latest Products</h3>
                <div className="block-header__divider" />
              </div>
              <div className="block-product-columns__column">
                {bestSelllersList.data.map((item, key) => (
                  <div key={key} className="block-product-columns__item">
                    <div className="product-card product-card--layout--horizontal">
                      <button type="button" class="product-card__quickview">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                          <path d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z  M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z"></path>
                        </svg>
                      </button>
                      <div className="product-card__badges-list">
                        {/* <div className="product-card__badge product-card__badge--new">New</div> */}
                      </div>
                      <div className="product-card__image product-image">
                        {/* <a className="product-image__body" href="/shop/products/Polenta and cured meats"> */}
                        <a
                          href={`/product/${item.id}/${item.attributes['product.url']}`}
                          className="product-image__body"
                        >
                          <img
                            className="product-image__img"
                            src={
                              getProductDetailsMultiple(bestSelllersList, item).media
                                ? getProductDetailsMultiple(bestSelllersList, item).media
                                : 'images/products/product-7.jpg'
                            }
                            alt=""
                          />
                          {/* </a> */}
                        </a>
                      </div>
                      <a
                        href={`/product/${item.id}/${item.attributes['product.url']}`}
                        className="product-card__title_name"
                      >
                        {item.attributes['product.label']}
                        <div className="product-card__info">
                          <div className="product-card__name">
                            {/* <a
                              href={`/product/${item.id}/${item.attributes['product.url']}`}
                              className="product-card__title_name"
                            >
                              {item.attributes['product.label']}
                            </a> */}
                          </div>
                          <div className="product-card__rating"></div>
                        </div>
                        <div className="product-card__actions">
                          <div className="product-card__availability">
                            Availability: <span className="text-success">In Stock</span>
                          </div>
                          <div className="product-card__prices">
                            $
                            {getProductDetailsMultiple(bestSelllersList, item).price
                              ? getProductDetailsMultiple(bestSelllersList, item).price.attributes['price.value']
                              : '-'}
                          </div>
                          <div className="product-card__buttons">
                            <button type="button" className="btn btn-primary product-card__addtocart">
                              Add To Cart
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary product-card__addtocart product-card__addtocart--list"
                            >
                              Add To Cart
                            </button>
                            <button
                              type="button"
                              className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                                <path d="M13.9,8.4l-5.4,5.4c-0.3,0.3-0.7,0.3-1,0L2.1,8.4c-1.5-1.5-1.5-3.8,0-5.3C2.8,2.4,3.8,2,4.8,2s1.9,0.4,2.6,1.1L8,3.7 l0.6-0.6C9.3,2.4,10.3,2,11.3,2c1,0,1.9,0.4,2.6,1.1C15.4,4.6,15.4,6.9,13.9,8.4z" />
                              </svg>
                            </button>
                            <button
                              type="button"
                              className="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare d-none"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}>
                                <path d="M9,15H7c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v12C10,14.6,9.6,15,9,15z" />
                                <path d="M1,9h2c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1v-4C0,9.4,0.4,9,1,9z" />
                                <path d="M15,5h-2c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {useMemo(
        () => (
          <BlockProductColumns columns={columns} />
        ),
        [columns]
      )} */}
    </React.Fragment>
  );
}

export default HomePageTwo;

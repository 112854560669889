import React from 'react';

const ReferringFriend = () => {
  return (
    <>
      <div className="page-header">
        <div className="page-header__container container">
          <div className="page-header__breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                  {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                    <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                  </svg>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <a href="/">Shop</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                                        <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                                    </svg>
                                </li> */}
                <li className="breadcrumb-item active">
                  <a href="/referringFriend">Referring</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="referring-friend">
        <div className="container">
          <div className="row">
            <h1 className="mb-3">Referring A Friend</h1>
            <p>
              The people who are most likely to appreciate the experience of The Cottage Butcher are the friends and
              neighbors who are already enjoying our service!
            </p>
            <h5>Referral incentives - For them</h5>
            <p>
              When someone you've referred to us signs up for the “Cut Above” Rewards Program or the Cottage Crate
              subscription, we give them a free Welcome Bundle in their first delivery!
            </p>
            <h5>Referral incentives - For you</h5>
            <p>What do you get for making successful referrals?</p>
            <p>First referral: Your referred friend's gratitude and 5% off your next order</p>
            <p>Second referral: You get 250 Cut Above Rewards points for any order over $50</p>
            <p>Third referral: You get 500 Cut Above Rewards points for any order over $50</p>
            <p>Fourth referral: You get $50 off your next order of $250 or more</p>
            <p>Fifth referral: Sooooo much gratitude!!!... plus $200 off your next order of %150 or more</p>
            <p>It's basically a gift to your friend from you, that doesn't cost you anything!</p>
            <h5>How to refer</h5>
            <p>
              It's pretty straightforward. All your friends need to do is add your phone number, name or email address
              in the referral field. it’s that easy – and that's enough for us to issue incentives!
            </p>
            <p>
              Not sure if they did that for you? Just shoot us and email or give us a ring, and we will help make sure
              you get the well-deserved credit!.
            </p>
            <h5>Thank you!</h5>
            <p>
              Thanks for spreading the word to friends and neighbors! It's the most rewarding way to build our
              community.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferringFriend;

import React from 'react';

const OrderAdd = () => {
  return (
    <>
      <div className="page-header">
        <div className="page-header__container container">
          <div className="page-header__breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                  {/* <i class="fa fa-angle-right breadcrumb-arrow"></i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                    <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                  </svg>
                </li>
                {/* <li className="breadcrumb-item active">
                  <a href="/">Shop</a>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" class="breadcrumb-arrow">
                    <path d="M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z" />
                  </svg>
                </li> */}
                <li className="breadcrumb-item active">
                  <a href="/order-add">Order Shipping</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="order-shipping ">
        <div className="container">
          <div className="row">
            <h1 className="mb-3">Order Shipping</h1>
            <p>
              If you have ordered a Cottage Crate Subscription or placed an order for shipping to your door, we send you
              an email to confirm your expected shipping and delivery date. For subscriptions, we will also tell you the
              day in our monthly delivery cycle when we'll be delivering to you.
            </p>
            <h5>Standard Shipping</h5>
            <p>
              Not in our delivery area? We ship everywhere in the US and Canada. We ship Monday/Friday and weekend
              delivery is also available in about 80% of the country. All orders ship with dry ice which will not melt
              in transit. Next Day Air and Priority orders ship first. Prices vary depending on the destination. The
              daily order cut-off time is 12:00 pm (noon) EST.
            </p>
            <p>
              Orders will ship based on inventory availability, most orders are fulfilled in less than 24 hours.
              However, depending on the product, fulfillment may take up to 4 days. If you require something faster,
              please contact us.
            </p>
            <p>
              Your order will be handpicked, cut, sealed, and carefully packed in an insulated box with reusable ice
              packs to maintain your items in good condition for up to 56 hours. Please note items may begin to thaw
              during transit. As long as items arrive cold to the touch, we consider them safe for consumption.
            </p>
            <h5>Shipping restrictions</h5>
            <ul className="ps-5">
              <li>Some carriers might not leave packages in open areas (apartment or condo complexes).</li>
              <li>
                We do not currently ship to APO and other Military locations due to the perishable nature of our
                products.
              </li>
              <li>We do not ship internationally (beyond the US and Canada) due to customs laws.</li>
              <li>
                Please double-check addresses. We are not responsible for incorrect or incomplete shipping addresses.
              </li>
              <li>
                Inclement weather and other unforeseen circumstances may delay your shipment. We are not responsible for
                shipment delays caused by adverse or unpredictable weather conditions.
              </li>
            </ul>
            <h5>Return Policy</h5>
            <ul className="ps-5">
              <li>Due to the perishable nature of the product, we cannot accept returned merchandise.</li>
              <li>
                We will not issue a refund to anyone’s credit card who refuses a delivery or in any way attempts to
                return a shipment.
              </li>
              <li>
                Please check all addresses carefully. Damage due to incorrect or incomplete shipping addresses voids our
                guarantee so please be sure to double-check addresses.
              </li>
              <li>The customer will then be responsible for the charges of a new order.</li>
            </ul>
            <h5>Cottage Crate Subscriptions</h5>
            <p>
              Your Cottage Crate subscription is very flexible. It's pay-as-you-go, and you can cancel any time with no
              penalty. Additionally, you pick your delivery frequency: monthly or every-other-month (and in some cases
              you may choose every third month). Finally, you can always choose to skip a delivery month.
            </p>
            <ul className="ps-5">
              <li>
                You can let us know if you have any conflicts with the target delivery date - We work around conflicts!
                (More detail)
              </li>
              <li>You may always choose to push your subscription delivery back a month (or more).</li>
              <li>
                You can also pick one-time Add-ons to supplement your subscription - right up until the day before your
                delivery!
              </li>
              <li>
                Your order arrive in an insulated bag with dry ice. We quick-freeze and vacuum-seal meat at peak
                freshness, and it reaches you still sealed and frozen.
              </li>
              <li>You don't need to be home precisely at delivery time. And you will be alerted to delivery.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderAdd;

// react
import React, { useState, useRef, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { ShowToast } from '../../services/helpers';

// application
import PageHeader from '../shared/PageHeader';
import { API_LOGIN, API_REGISTER, API_REGISTER_PHONE } from '../../config/endpoints';
import { getUserData, setUserData, getLocalData, deleteLocalData } from '../../services/storage';
const loggedInUser = getUserData();

// data stubs
import theme from '../../data/theme';

export default function AccountPageLogin() {
  const breadcrumb = [
    { title: 'Home', url: '' },
    { title: 'Welcome to the Cut Above Rewards Program', url: '' },
  ];
  const location = useLocation();
  const passedData = location.state && location.state.phoneNumber;

  const [isBtnLoading, setIsBtnLoading] = useState('');
  const [isRegisterBtnLoading, setIsRegisterBtnLoading] = useState('');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const simpleValidatorLogin = useRef(new SimpleReactValidator());
  const [, forceUpdateLogin] = useState();
  const [, forceUpdate] = useState();

  const [registerEmail, setRegisterEmail] = useState('');
  const [registerFirstName, setRegisterFirstName] = useState('');
  const [registerLastName, setRegisterLastName] = useState('');
  const [registerPhoneNumber, setRegisterPhoneNumber] = useState(passedData ? passedData : '');

  // const [registerPasssword, setRegisterPasssword] = useState('');
  // const [registerConfirmPasssword, setRegisterConfirmPasssword] = useState('');
  const [registerDOB, setRegisterDOB] = useState('');

  useEffect(() => {
    if (loggedInUser.isLoggedIn) {
      window.location = '/account/dashboard';
    }
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    const formValid = simpleValidatorLogin.current.allValid();
    if (!formValid) {
      simpleValidatorLogin.current.showMessages();
      forceUpdateLogin(1);
    } else {
      setIsBtnLoading(true);
      await fetch(API_LOGIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        crossDomain: true,
        credentials: 'include',
        body: JSON.stringify({ email, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            ShowToast('error', data.message);
          } else {
            ShowToast('success', 'Logged In.');
            setUserData(data);
            const redirectUrl = getLocalData('redirect');
            if (redirectUrl) {
              deleteLocalData('redirect');
              window.location = redirectUrl;
            } else {
              window.location = '';
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsBtnLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsRegisterBtnLoading(true);
      await fetch(API_REGISTER, {
        method: 'POST',
        crossDomain: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: registerEmail,
          firstname: registerFirstName,
          lastname: registerLastName,
          password: registerPasssword,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            const errorList = data.error;
            Object.keys(errorList).forEach(function (key) {
              ShowToast('error', errorList[key][0]);
            });
          } else {
            ShowToast('success', 'Signed Up.');
            setUserData(data);
            const redirectUrl = getLocalData('redirect');
            if (redirectUrl) {
              deleteLocalData('redirect');
              window.location = redirectUrl;
            } else {
              window.location = '';
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsRegisterBtnLoading(false);
    }
  };

  const handlePhoneRegister = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsRegisterBtnLoading(true);
      await fetch(API_REGISTER_PHONE, {
        method: 'POST',
        crossDomain: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname: registerFirstName,
          lastname: registerLastName,
          phone: registerPhoneNumber,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            const errorList = data.error;
            Object.keys(errorList).forEach(function (key) {
              ShowToast('error', errorList[key][0]);
            });
          } else {
            ShowToast('success', 'Signed Up.');
            setUserData(data);
            window.location = '';

            // const redirectUrl = getLocalData('redirect');
            // if (redirectUrl) {
            //   deleteLocalData('redirect');
            //   window.location = redirectUrl;
            // } else {
            //   window.location = '';
            // }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsRegisterBtnLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login — ${theme.name}`}</title>
      </Helmet>

      <PageHeader header="Welcome to the Cut Above Rewards Program" breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          {/* <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card flex-grow-1 mb-md-0">
                <div className="card-body">
                  <h3 className="card-title">Login</h3>
                  <form>
                    <div className="form-group">
                      <label htmlFor="login-email">Email address</label>
                      <input
                        id="login-email"
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => simpleValidatorLogin.current.showMessageFor('email')}
                      />
                      {simpleValidatorLogin.current.message('email', email, 'required|email')}
                    </div>
                    <div className="form-group">
                      <label htmlFor="login-password">Password</label>
                      <input
                        id="login-password"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={() => simpleValidatorLogin.current.showMessageFor('password')}
                      />
                      {simpleValidatorLogin.current.message('password', password, 'required|min:8')}
                      <small className="form-text text-muted">
                        <Link to="/site/password-forget">Forgot Password?</Link>
                      </small>
                    </div>
                    <button
                      type="submit"
                      disabled={isBtnLoading}
                      onClick={handleLogin}
                      className="btn btn-primary mt-2 mt-md-3 mt-lg-4"
                    >
                      Login
                      <i className={isBtnLoading ? `fa fa-spin fa-spinner` : ``} />
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex mt-4 mt-md-0">
              <div className="card flex-grow-1 mb-0">
                <div className="card-body">
                  <h3 className="card-title">Register</h3>
                  <form>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="first-name">First Name</label>
                          <input
                            id="first-name"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="registerFirstName"
                            onChange={(e) => setRegisterFirstName(e.target.value)}
                            onBlur={() => simpleValidator.current.showMessageFor('registerFirstName')}
                          />
                          {simpleValidator.current.message('registerFirstName', registerFirstName, 'required|min:4')}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="last-name">Last Name</label>
                          <input
                            id="last-name"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="registerLastName"
                            onChange={(e) => setRegisterLastName(e.target.value)}
                            onBlur={() => simpleValidator.current.showMessageFor('registerLastName')}
                          />
                          {simpleValidator.current.message('registerLastName', registerLastName, 'required|min:4')}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="register-email">Email address</label>
                      <input
                        id="register-email"
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        name="email"
                        onChange={(e) => setRegisterEmail(e.target.value)}
                        onBlur={() => simpleValidator.current.showMessageFor('registerEmail')}
                      />
                      {simpleValidator.current.message('registerEmail', registerEmail, 'required|email')}
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="register-password">Password</label>
                          <input
                            id="register-password"
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            onChange={(e) => setRegisterPasssword(e.target.value)}
                            onBlur={() => simpleValidator.current.showMessageFor('registerPassword')}
                          />
                          <small>(Must be min of 8 characters.)</small>
                          {simpleValidator.current.message('registerPassword', registerPasssword, 'required|min:8')}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="register-confirm">Repeat Password</label>
                          <input
                            id="register-confirm"
                            type="password"
                            className="form-control"
                            placeholder="Password"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={isRegisterBtnLoading}
                      onClick={handleRegister}
                      className="btn btn-primary mt-2 mt-md-3 mt-lg-4"
                    >
                      Register
                      <i className={isRegisterBtnLoading ? `fa fa-spin fa-spinner` : ``} />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-2 d-flex"></div>
            <div className="col-md-8 d-flex mt-4 mt-md-0">
              <div className="card flex-grow-1 mb-0">
                <div className="card-body">
                  <h3 className="card-title">Register</h3>
                  <form>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="first-name">First Name</label>
                          <input
                            id="first-name"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="registerFirstName"
                            onChange={(e) => setRegisterFirstName(e.target.value)}
                            onBlur={() => simpleValidator.current.showMessageFor('registerFirstName')}
                          />
                          {simpleValidator.current.message('registerFirstName', registerFirstName, 'required|min:4')}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="last-name">Last Name</label>
                          <input
                            id="last-name"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="registerLastName"
                            onChange={(e) => setRegisterLastName(e.target.value)}
                            onBlur={() => simpleValidator.current.showMessageFor('registerLastName')}
                          />
                          {simpleValidator.current.message('registerLastName', registerLastName, 'required|min:4')}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="register-email">Email address</label>
                          <input
                            id="register-email"
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            name="email"
                            onChange={(e) => setRegisterEmail(e.target.value)}
                            onBlur={() => simpleValidator.current.showMessageFor('registerEmail')}
                          />
                          {simpleValidator.current.message('registerEmail', registerEmail, 'email')}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="DOB">DOB</label>
                          <input
                            id="DOB"
                            type="date"
                            className="form-control"
                            placeholder="DOB"
                            name="registerDOB"
                            onChange={(e) => setRegisterDOB(e.target.value)}
                            onBlur={() => simpleValidator.current.showMessageFor('registerDOB')}
                          />
                          {simpleValidator.current.message('registerDOB', registerDOB, '')}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="profile-number">Mobile Number</label>
                          <input
                            type="tel"
                            className="form-control"
                            defaultValue={passedData}
                            placeholder="Mobile Number"
                            maxLength="10"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={isRegisterBtnLoading}
                      onClick={handlePhoneRegister}
                      className="btn btn-primary mt-2 mt-md-3 mt-lg-4"
                    >
                      Register
                      <i className={isRegisterBtnLoading ? `fa fa-spin fa-spinner` : ``} />
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-2 d-flex"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
